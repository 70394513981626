import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, Typography, Container, Box, Paper, Grid, Link, CssBaseline, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import { AuthContext } from '../AuthContext'; // certifique-se de importar o contexto corretamente

const theme = createTheme({
    palette: {
        primary: { main: '#3f51b5' },
        secondary: { main: '#f50057' }
    },
    typography: {
        fontFamily: 'Arial, sans-serif',
        h4: { fontWeight: 700 },
        body1: { fontSize: '1.1rem' }
    }
});

const formatCPF = (value) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
        .substr(0, 14);
};

const formatCNPJ = (value) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{4})(\d{2})/, '$1/$2-$3')
        .substr(0, 18);
};

const AuthForm = () => {
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [isTokenSent, setIsTokenSent] = useState(false);
    const [isLoginView, setIsLoginView] = useState(true);
    const [name, setName] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [cpfCnpjType, setCpfCnpjType] = useState('cpf');
    const [errors, setErrors] = useState({});
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [resendTimer, setResendTimer] = useState(300);
    const navigate = useNavigate();
    const { login } = useContext(AuthContext); // Utilize o contexto para acessar o método login

    const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3002/api";

    useEffect(() => {
        let timer;
        if (isResendDisabled && resendTimer > 0) {
            timer = setInterval(() => {
                setResendTimer(prev => prev - 1);
            }, 1000);
        } else if (resendTimer === 0) {
            setIsResendDisabled(false);
            setResendTimer(300);
        }
        return () => clearInterval(timer);
    }, [isResendDisabled, resendTimer]);

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return re.test(String(email).toLowerCase());
    };

    const validateForm = () => {
        let fieldErrors = {};
        if (!name) {
            fieldErrors.name = "Nome é obrigatório.";
        }
        if (cpfCnpjType === 'cpf' && !cpfValidator.isValid(cpfCnpj)) {
            fieldErrors.cpfCnpj = "CPF inválido.";
        }
        if (cpfCnpjType === 'cnpj' && !cnpjValidator.isValid(cpfCnpj)) {
            fieldErrors.cpfCnpj = "CNPJ inválido.";
        }
        if (!validateEmail(email)) {
            fieldErrors.email = "Email inválido.";
        }

        setErrors(fieldErrors);
        return Object.keys(fieldErrors).length === 0;
    };

    const handleSendToken = async () => {
        try {
            // Verifique se o token já foi enviado e retorne se já foi.
            if (isTokenSent) return;

            const checkUserResponse = await axios.post(`${apiUrl}/auth/check-user`, { email, cpfCnpj });

            if (!checkUserResponse.data.exists) {
                alert('Usuário não encontrado. Por favor, cadastre-se.');
                setIsLoginView(false);
                return;
            }

            const response = await fetch(`${apiUrl}/auth/send-token`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            setIsTokenSent(true);
            setIsResendDisabled(true);
            alert('Token enviado ao seu endereço de email.');
        } catch (error) {
            console.error('Erro ao enviar token:', error.message);
            alert('Erro ao enviar token. Por favor, tente novamente.');
        }
    };

    const handleVerifyToken = async () => {
        try {
            const response = await fetch(`${apiUrl}/auth/verify-token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, token })
            });

            if (!response.ok) {
                throw new Error(`Erro HTTP! status: ${response.status}`);
            }

            const data = await response.json();
            console.log('Resposta recebida:', data);
            if (data.message === "Autenticação bem-sucedida") {
                console.log('Token recebido:', data.token);
                localStorage.setItem('auth-token', data.token);
                login({ user: data.user, token: data.token });
                handleAuthSuccess(data.user);
            } else {
                alert('Token inválido.');
            }
        } catch (error) {
            console.error('Erro ao verificar token:', error.message);
            alert('Erro ao verificar token. Por favor, tente novamente.');
        }
    };

    const handleAuthSuccess = (user) => {
        if (!user) {
            console.error('Erro: O objeto usuário está ausente.');
            return;
        }
        const { name, createdAt } = user;
        if (name && createdAt) {
            alert(`Bem-vindo(a), ${name}! Sua conta foi criada em ${new Date(createdAt).toLocaleDateString()}`);
            navigate('/success');
        } else {
            console.error('Erro: Dados do usuário estão faltando ou inválidos', user);
            alert('Dados do usuário estão faltando ou inválidos. Por favor, tente novamente.');
        }
    };

    const handleRegister = async () => {
        if (!validateForm()) {
            return;
        }

        try {
            const response = await axios.post(`${apiUrl}/auth/check-user`, { email, cpfCnpj });

            if (response.data.exists) {
                alert('Usuário já registrado. Por favor, faça login.');
                setIsLoginView(true);
                return;
            }

            const determineCpfCnpjType = (cpfCnpj) => {
                if (cpfCnpj.length === 11) {
                    return 'cpf';
                } else if (cpfCnpj.length === 14) {
                    return 'cnpj';
                } else {
                    throw new Error('Formato inválido de CPF/CNPJ');
                }
            };

            const cpfCnpjType = determineCpfCnpjType(cpfCnpj);

            await axios.post(`${apiUrl}/auth/register`, { email, plan: 'free', name, cpfCnpj, cpfCnpjType });
            await handleSendToken(); // Somente enviar token se o registro for bem-sucedido
        } catch (error) {
            console.error('Erro ao registrar:', error);
            const errorMessage = error.response?.data?.message || 'Erro ao registrar. Por favor, tente novamente.';
            alert(errorMessage);
        }
    };


    const handleCpfCnpjChange = (e) => {
        const value = e.target.value;
        const formattedValue = cpfCnpjType === 'cpf' ? formatCPF(value) : formatCNPJ(value);
        setCpfCnpj(formattedValue);

        if ((cpfCnpjType === 'cpf' && cpfValidator.isValid(value.replace(/\D/g, ''))) ||
            (cpfCnpjType === 'cnpj' && cnpjValidator.isValid(value.replace(/\D/g, '')))) {
            setErrors((prevState) => ({ ...prevState, cpfCnpj: '' }));
        } else {
            setErrors((prevState) => ({ ...prevState, cpfCnpj: cpfCnpjType === 'cpf' ? 'CPF inválido.' : 'CNPJ inválido.' }));
        }
    };

    const handleCpfCnpjTypeChange = (event, newValue) => {
        if (newValue !== null) {
            setCpfCnpjType(newValue);
            setCpfCnpj('');
            setErrors((prevState) => ({ ...prevState, cpfCnpj: '' }));
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppHeader />
            <Container maxWidth="sm">
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ background: 'white', color: 'black', padding: 4 }}
                >
                    <Paper elevation={6} style={{ borderRadius: 16, padding: '2rem', marginTop: theme.spacing(2) }}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            {isLoginView ? 'Entrar' : 'Registrar & Verificar'}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {isLoginView
                                ? 'Por favor, forneça seu email para entrar.'
                                : 'Por favor, registre-se fornecendo seus dados. Um token será enviado ao seu email para verificação.'}
                        </Typography>

                        {/* Mensagem de Atenção Adicionada */}
                        {!isLoginView && (
                            <Box my={2}>
                                <Typography variant="body2" color="error" align="center">
                                    Atenção: O e-mail de cadastro deve ser o mesmo e-mail a ser utilizado durante a compra.
                                </Typography>
                            </Box>
                        )}

                        <Grid container spacing={2}>
                            {!isLoginView && (
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Nome da Empresa ou Pessoa"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            error={!!errors.name}
                                            helperText={errors.name}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ToggleButtonGroup
                                            value={cpfCnpjType}
                                            exclusive
                                            onChange={handleCpfCnpjTypeChange}
                                            aria-label="CPF ou CNPJ"
                                            fullWidth
                                        >
                                            <ToggleButton value="cpf" aria-label="CPF">
                                                CPF
                                            </ToggleButton>
                                            <ToggleButton value="cnpj" aria-label="CNPJ">
                                                CNPJ
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label={cpfCnpjType === 'cpf' ? 'CPF' : 'CNPJ'}
                                            value={cpfCnpj}
                                            onChange={handleCpfCnpjChange}
                                            error={!!errors.cpfCnpj}
                                            helperText={errors.cpfCnpj}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Endereço de Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    onBlur={() => {
                                        if (!validateEmail(email)) {
                                            setErrors(prevState => ({
                                                ...prevState,
                                                email: 'Email inválido.',
                                            }));
                                        } else {
                                            setErrors(prevState => ({
                                                ...prevState,
                                                email: '',
                                            }));
                                        }
                                    }}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                    disabled={isTokenSent}
                                />
                            </Grid>
                            {isTokenSent && (
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="Token"
                                        value={token}
                                        onChange={(e) => setToken(e.target.value)}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} container spacing={2}>
                                {isTokenSent && (
                                    <Grid item xs={6}>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            fullWidth
                                            onClick={handleSendToken}
                                            disabled={isResendDisabled}
                                        >
                                            Reenviar Token {isResendDisabled ? `(${resendTimer}s)` : ''}
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item xs={isTokenSent ? 6 : 12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={isTokenSent ? handleVerifyToken : isLoginView ? handleSendToken : handleRegister}
                                    >
                                        {isTokenSent ? 'VERIFICAR TOKEN' : isLoginView ? 'ENTRAR' : 'REGISTRAR'}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" align="center">
                                    {isLoginView ? (
                                        <>
                                            Não possui conta?{' '}
                                            <Link component="button" variant="body2" onClick={() => setIsLoginView(false)}>
                                                Registre-se agora
                                            </Link>
                                        </>
                                    ) : (
                                        <>
                                            Já possui conta?{' '}
                                            <Link component="button" variant="body2" onClick={() => setIsLoginView(true)}>
                                                Entrar
                                            </Link>
                                        </>
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </Container>
            <AppFooter />
        </ThemeProvider>
    );
};

export default AuthForm;
